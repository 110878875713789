.section-title {
  font-size: 24px;
  text-align: left;
  padding: 40px 0 0 0;
}

.blue-button {
  background: #0a56f7;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  padding: 8px 20px;
}

.datatable-container {
  width: 90%;
  margin: 0px auto 30px auto;
  padding-top: 80px;
  min-height: calc(100vh - 200px);
}

.form-group {
  margin-bottom: 25px !important;
}

.data-table-header {
  margin: 15px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.data-table-header .data-table-search-box {
  max-width: 350px;
  margin-top: 20px;
  display: flex;
}
.data-table-header .data-table-search-box input {
  margin-right: 10px;
}
.data-table-header .database-header-actions {
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.data-table-header .data-table-action-buttons {
  margin-left: 10px;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.table {
  margin-bottom: 0 !important;
}

.data-table-container {
  border-radius: 10px;
  overflow: hidden;
  margin: 50px auto 0 auto;
  border: 1px solid #1e1f21;
}
.data-table-container thead {
  background: #1e1f21 !important;
}
.data-table-container thead tr {
  background: #1e1f21 !important;
}
.data-table-container thead th {
  background: #1e1f21 !important;
  color: white;
  border-right: 1px solid white;
  font-size: 16px;
  padding: 5px 0;
}
.data-table-container thead th:last-child {
  border-right: none;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-container span {
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}

.pagination-container .active-page-number {
  color: #1ED8B9;
}

.actions-dropdown {
  cursor: pointer;
  font-size: 20px;
}

.actions-list {
  list-style-type: none;
  font-size: 15px;
}
.actions-list li {
  padding: 5px 20px;
  cursor: pointer;
}
.actions-list li:hover {
  background: #0a56f7;
  color: white;
}

.actions-dropdown-box {
  position: absolute;
  margin-top: 30px;
  background: white;
  padding: 8px 0;
  box-shadow: 0 0 8px 0 gray;
  border-radius: 8px;
}
.actions-dropdown-box .actions-list {
  color: black !important;
}

.actions-list {
  padding: 0;
  margin: 0;
}

.no-padding {
  padding: 0 !important;
}

.data-table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto auto auto;
}

.select-filters {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.payment-needed-row {
  background: red !important;
  color: white !important;
}

.payed-row {
  background: greenyellow !important;
}

.add-order-detail-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.table-actions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (max-width: 1999px) {
  .data-table-header {
    justify-content: space-between;
  }
}/*# sourceMappingURL=DataTable.css.map */
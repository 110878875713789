.car-details-wrapper {
  min-height: calc(100vh - 200px);
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 80px;
  width: 90%;
  margin: auto;
}
.car-details-wrapper .car-detail-page-content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.car-details-wrapper .car-detail-page-divider {
  width: calc(50% - 20px);
}
.car-details-wrapper .car-detail-page-divider h3, .car-details-wrapper .car-detail-page-divider h6 {
  text-align: left;
}
.car-details-wrapper .car-detail-page-divider h3 span, .car-details-wrapper .car-detail-page-divider h6 span {
  color: gray;
}
.car-details-wrapper .car-details-information-box {
  margin-top: 50px;
}
.car-details-wrapper .car-details-information-box h5 {
  text-align: left;
}
.car-details-wrapper .car-details-information-content {
  width: 100%;
  padding: 0;
  margin-top: 15px;
}
.car-details-wrapper .car-details-information-content li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.car-details-wrapper .car-details-information-content span {
  width: 50%;
  text-align: left;
}
.car-details-wrapper .car-details-information-content .car-details-titles {
  color: gray;
}

.add-new-button {
  margin-right: 20px;
}

.description-box {
  margin-top: 50px;
}
.description-box h4 {
  text-align: left;
}
.description-box .car-description-text {
  font-size: 17px;
  color: rgb(57, 56, 56);
  padding: 15px;
  max-width: 750px;
  width: 100%;
  text-align: left;
}

@media (max-width: 775px) {
  .car-details-wrapper .car-detail-page-content {
    flex-direction: column;
  }
  .car-details-wrapper .car-detail-page-divider {
    width: 100%;
  }
  .car-details-wrapper .car-details-header-content {
    margin-top: 40px;
  }
}/*# sourceMappingURL=market.css.map */
.filter-cars-container {
  width: 90%;
  max-width: 1000px;
  position: relative;
  margin: 70px auto auto auto;
  background: white;
  z-index: 11;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px -2px 26px -7px rgba(111, 98, 98, 0.89);
  -webkit-box-shadow: 0px -2px 26px -7px rgba(111, 98, 98, 0.89);
  -moz-box-shadow: 0px -2px 26px -7px rgba(111, 98, 98, 0.89);
}

.filter-cars-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.search-fiealds {
  height: 45px;
}

@media (max-width: 1048px) {
  .filter-cars-content .MuiAutocomplete-root, .filter-cars-content button {
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=MainPageFilter.css.map */
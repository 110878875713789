.aside-search-modal-container {
    width: 50px;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    z-index: 1111111111;
    overflow: auto;
    padding-bottom: 40px;

    &::-webkit-scrollbar {
        width: 0;
        /* Hide the horizontal scrollbar */
        height: 0;
        /* Hide the vertical scrollbar */
    }
}

.catalogue-page-container {
    width: 90%;
    margin: auto;
    padding-top: 115px;
}

.show-hide-search-aside {
    background: rgba(#1565c0, 0.8);
    border: none;
    width: 100%;
    padding: 10px 0px;
    border-top: 1px solid #1565c0;
    border-bottom: 1px solid #1565c0;
}

.hide-aside {
    width: 50px;
    transition: 0.5s;
    height: fit-content;
    padding-bottom: 0;

    .show-hide-search-aside {
        margin-top: 90px;
        transition: 0.5s;
    }

    .menu-btn {
        margin-top: 190px;
    }

    .search-filter-content {
        display: none;
    }
}

.show-aside {
    width: 300px;
    background: rgba($color: #ffffff, $alpha: 1);
    box-shadow: 10px 0 10px rgba(126, 126, 126, 0.2);
    transition: 0.5s;

    .show-hide-search-aside {
        margin-top: 30px;
        transition: 0.5s;
    }

    .search-filter-content {
        display: block;
        width: 90%;
        margin: auto;
    }
}

.catalogue-marks-selector {
    overflow: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    &::-webkit-scrollbar {
        width: 0;
        /* Hide the horizontal scrollbar */
        height: 0;
        /* Hide the vertical scrollbar */
    }
}

.catalogue-years-selector {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .catalogue-year-list {
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
            /* Hide the horizontal scrollbar */
            height: 0;
            /* Hide the vertical scrollbar */
        }
    }
}

.show-this-section {
    height: 300px;
    transition: 0.5s;
}

.hide-this-section {
    height: 40px;
    transition: 0.5s;
}

.search-filter-section-title {
    margin: 0;
    font-size: 19px;
}

.catalogue-marks-list {
    padding: 0px;
    padding-bottom: 10px;

    li {
        cursor: pointer;
        padding: 7px 0px;

        &:hover {
            background: #1565c0;
            color: white;
        }
    }

    .selected-year {
        background: #1565c0;
        color: white;
    }
}

.catalogue-year-list {
    padding: 0px;
    width: 48%;
    padding-bottom: 10px;

    li {
        cursor: pointer;
        padding: 7px 0px;

        &:hover {
            background: #1565c0;
            color: white;
        }
    }
}

.filter-sections-header {
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 10px 0;
    border-bottom: 1px solid rgb(224, 224, 224);
}

.filter-section-prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
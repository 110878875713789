.main-page-cars-catalogue {
  width: 90%;
  margin: 70px auto 0px auto;
}

.catalogue-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}/*# sourceMappingURL=MainPageCatalog.css.map */
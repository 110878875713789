.contact-page-content{
    width: 90%;
    margin: 70px auto 50px auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h4{
        margin-bottom: 35px;
    }
}
.contact-page-container{
    padding-top: 81px;
}
.contact-form-container{
    width: 48%;
    .contact-form-box{
        display: flex;
        flex-direction: column;
    }
}
.contact-page-fields{
    margin-bottom: 35px !important;
}
.contact-details-container{
    width: 48%;
}

@media(max-width: 800px){
    .contact-page-content{
        flex-direction: column;
    }
    .contact-form-container{
        width: 100%;
        margin-bottom: 50px;
    }
    .contact-details-container{
        width: 100%;
        iframe{
            height: 330px !important;
        }
    }
}
.car-item-container {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  border: #e4e4e4 1px solid;

  .car-item-image {
    height: 220px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .car-details-container {
    padding: 8px;
    background: none !important;
  }
  .car-details-name {
    text-align: left;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0;
    color: black !important;
  }

  .car-details-primary-damage {
    text-align: left;
    font-size: 14px;
    color: gray;
    margin-bottom: 0px;
  }

  .hr {
    margin: 8px 0px;
    border-color: #bcbcbc;
  }

  .car-details-city-and-bid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .car-details-high-bid {
    margin-bottom: 0px;
    color: #FF204C;
  }

  .car-details-currency {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 0px;
    color: #FF204C;
  }

  .car-details-buy-now-amount {
    text-align: left;
  }
}

.car-conditions-icon {
  height: 70px;
}

.market-edit-button{
  position: absolute;
  z-index: 11;
  border: #FB224A solid 1px;
  border-radius: 8px;
  padding: 4px 8px;
  background: none;
  margin-top: 10px;
  :hover{
    transform: scale(1.1);
  }
  img{
    width: 20px;
  }
}

.car-images-wrapper{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span{
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
  }
}

a {
  text-decoration: none !important;
}

.section-header-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  h4 {
    margin-bottom: 0;
  }
}

.slider-content{
  width: 48%;
}

.car-details-title{
  font-size: 23px;
}

.main-page-cars-catalogue {
  margin-bottom: 50px;
  overflow: hidden;
}

.slider-items-car-container{
  margin-right: 20px;
}

.slide-switcher-button{
  width: fit-content;
  height: fit-content;
  padding: 5px 20px;
  background: #03346b;
  border: #03346b 2px solid;
  color: white;
  border: none;
  margin-left: 8px;
  border-radius: 6px;
  font-size: 20px;
  &:hover{
    background: #0a55ac;
    transition: 0.5s;
  }
}

.page-content-container {
  padding-top: 100px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.add-to-watch-list {
  padding: 5px 15px;
  margin-bottom: 25px;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  color: #333333;
  font-weight: bold;
  background: none;
}

.remove-watch {
  padding: 5px 15px;
  margin-bottom: 25px;
  border-radius: 8px;
  border: 1px solid #f16e6e;
  color: #f16e6e;
  font-weight: bold;
  background: none;
}

.slider-image-item {
  width: 100%;
  border-radius: 8px;
  max-width: 750px;
}
.slider-image-skeleton{
  width: 730px;
}

.slider-items-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
    /* Hide the horizontal scrollbar */
    height: 0;
    /* Hide the vertical scrollbar */
  }
}

.slider-box {
  width: 100%;
  max-width: 730px
}

.slider-items-item {
  width: calc((100% / 5) - 8px);
  margin-bottom: 8px;
  height: 100px;
  border-radius: 15px;
  margin-right: 8px;
  background-size: cover;
  flex-shrink: 1;
  background-position: center;
  background-repeat: no-repeat;
  border: 3px solid white;
  cursor: pointer;
}

.active-slide {
  border-color: #1565c0;
}

.car-details-description {
  width: 48%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;

  h3, h6 {
    text-align: left;
  }

  .high-bid-amount {
    text-align: right;

    h3 {
      color: #08d108;
      text-align: right;
    }

  }
}

.car-descriptions-container {
  margin-top: 28px;
  background: #f8f8f8;
  position: absolute;
  width: 100%;
  padding: 14px;
  border-radius: 8px;

  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  h6 {
    width: 50%;
  }
}

.consultation-section {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sales-manager-account {
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 17px;
    margin-left: 12px;
  }
}

.car-details-actions-section {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.condition-details {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    div {
      margin-left: 25px;
      text-align: left;
    }
  }
}

.bidding-section-container {
  width: 400px;
  border-radius: 8px;
  padding: 15px;
  background: #f8f8f8;
}

.car-detail-status-label {
  color: gray;
  margin-bottom: 5px;
}

.car-condition-details {
  width: 50%;
  background: #f8f8f8;
  border-radius: 8px;
}

.current-bid-amount {
  font-size: 18px;
  margin-bottom: 0;
  color: gray;

  span {
    color: #282c34;
    font-weight: bold;
  }
}

.place-your-highest-bid {
  width: 100%;
  border-radius: 8px;
  font-size: 17px;
  font-weight: bold;
}

.ask-for-consultation-image {
  width: 130px;
  border-radius: 6px;
}

.consultation-modal-details {
  display: flex;
  align-items: flex-start;

  h5, h6 {
    margin-left: 13px;
  }
}

.car-consultation-form {
  margin-top: 25px;
  input{
    margin-bottom: 13px;
  }
}

.consultation-section{
  background: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
}

.similar-cars-container{
  margin: 40px 0;
}

@media(max-width: 1100px){
  .page-content-container{
    flex-direction: column;
  }
  .slider-content{
    width: 100%;
  }
  .slider-box.carousel{
    max-width: 1100px !important;
  }
  .car-descriptions-container{
    position: relative;
    width: 100%;
  }
  .car-details-description{
    width: 100%;
  }
  .set-width{
    width: 100%;
  }
  .high-bid-amount{
    position: absolute;
    margin-left: 100%;
    transform: translateX(-100%);
    button{
      width: 145px;
    }
  }
  .car-details-actions-section{
    flex-direction: column;
    .car-condition-details, .bidding-section-container{
      width: 100%;
    }
    .car-condition-details{
      margin-bottom: 20px;
    }
  }
}
.show-watch{
  display: none;
}
.hide-watch{
  display: block;
}

@media(max-width: 500px){
  .consultation-text-hide{
    display: none;
  }
  .slider-items-item{
    height: 60px;
  }
  .car-details-title{
    font-size: 20px;
  }
  .set-width h6{
    font-size: 14px;
  }
  .show-watch{
    display: block;
  }
  .hide-watch{
    display: none;
  }
  .high-bid-amount button{
    width: 50px !important;
  }
}
.frequency-questions-block{
    background: white;
    border-radius: 12px;
    width: 90%;
    margin: 20px auto;
}
.question-container{
    width: 100%;
}
.questions-content{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    cursor: pointer;
}
.answer-block{
    width: 95%;
    padding: 10px 0;
    margin: auto;
}
.answer{
    font-size: 14px;
    color: #666666;
}
.footer-container {
  background: rgb(0, 0, 27);
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-container .footer-content {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-logo {
  width: 170px;
}

.footer-navigation {
  text-align: left;
}
.footer-navigation li {
  color: white;
}
.footer-navigation a {
  color: white !important;
}

.social-media-links {
  display: flex;
  align-items: center;
}
.social-media-links li:hover {
  transform: scale(1.2);
  transition: 0.5s;
}
.social-media-links a {
  margin-left: 15px;
}

.footer-button {
  background: rgb(0, 0, 66);
  border-radius: 15px;
  padding: 5px;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
}

.footer-contacts {
  text-align: left;
  color: white;
}
.footer-contacts a {
  color: white;
}

@media (max-width: 970px) {
  .footer-container {
    height: 240px;
  }
}
@media (max-width: 716px) {
  .footer-container {
    height: 280px;
  }
}
@media (max-width: 599px) {
  .footer-container {
    height: 320px;
  }
}
@media (max-width: 433px) {
  .footer-container {
    height: 480px;
  }
  .footer-navigation {
    margin-top: 1rem;
  }
}/*# sourceMappingURL=Footer.css.map */
.user-toggle-menu-button{
  z-index: 111;
  left: 0;
  top: 160px;
  position: fixed;
  width: 50px;
  height: 50px;
  background: #000042;
  border: #000042 1px solid;
  opacity: 0.9;
  cursor: pointer;
}
.authed-page-navigation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 78px);
  a{
    color: rgb(32, 32, 32);
    font-size: 17px;
    margin: 3px 0px;
  }
}

.hide-aside .authed-page-navigation{
  display: none;
}
.market-page-container {
  width: 100%;
  min-height: calc(100vh - 180px);
  padding-top: 80px;
  width: 90%;
  margin: auto;
}

.market-page-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-new-record-button {
  width: 100%;
  margin-top: 35px;
  height: 44px;
  background: #1976d2;
  border: #1976d2 1px solid;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

.loading-button {
  background: #b3cce6 !important;
  border: #b3cce6 1px solid;
}/*# sourceMappingURL=admin.css.map */
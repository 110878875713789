.header-container {
  position: fixed;
  width: 100%;
  z-index: 111;
  background: rgba(0, 0, 27, 0.8);
  padding: 7px 0px;
}
.header-container .header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.header-container .header-logo {
  width: 150px;
}

.navbar-container .nav-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
}
.navbar-container .nav-list li {
  margin: 0 12px;
}
.navbar-container .nav-list li a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
}

.avatar-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #1565c0;
  cursor: pointer;
}
.avatar-box span {
  color: white;
}

.sign-in-button {
  background: #fd375f;
  border: #fd375f 1px solid;
  padding: 4px 15px;
  color: white;
  border-radius: 8px;
}

.main-page-banner {
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scaleX(-1);
  z-index: -1;
}

.main-page-banner-content {
  width: 90%;
  transform: scaleX(-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-page-banner-content h1 {
  color: white;
  max-width: 450px;
  text-align: left;
  text-transform: uppercase;
  line-height: 60px;
  animation-name: show;
  animation-duration: 3s;
}

.header-background {
  background: rgb(0, 0, 27);
  transition: 0.5s;
}

.active-language-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 20px;
  border: 6px solid rgb(0, 0, 66);
  cursor: pointer;
  background-repeat: no-repeat;
}
.active-language-icon:hover {
  transform: scale(1.2);
  transition: 0.5s;
}

.map-container {
  height: 730px;
  overflow: hidden;
  width: 100%;
}

.check-title-form {
  padding: 30px 20px;
  background: #f8f8f8;
  border-radius: 30px;
  margin-top: 60px;
}
.check-title-form .section-title {
  padding-top: 0px;
}

.tracking-map {
  margin-top: -380px;
}

.languages-dropdown {
  position: absolute;
}

.languages-list {
  padding: 0;
  margin: 15px 0 0 0;
}
.languages-list li {
  margin-bottom: 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 6px solid rgb(0, 0, 66);
  cursor: pointer;
}

.header-buttons {
  display: flex;
  align-items: center;
}

@keyframes show {
  0% {
    display: none;
    margin-top: 150px;
    opacity: 0;
  }
  20% {
    display: block;
  }
  50% {
    margin-top: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1024px) {
  .navbar-container {
    display: none;
  }
}
@media (max-width: 700px) {
  .header-logo {
    width: 110px !important;
  }
}
@media (max-width: 630px) {
  .consultation-container h2 {
    font-size: 20px;
  }
  .consultation-container h5 {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .consultation-container {
    width: 90%;
    margin: 80px auto 80px auto !important;
    flex-direction: column !important;
  }
  .consultation-container .MuiFormControl-root {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .consultation-fields {
    width: 100%;
  }
  .services-sections-list .service-description-text {
    font-size: 18px !important;
  }
  .slide-switcher-button {
    padding: 2px 11px !important;
  }
}/*# sourceMappingURL=Header.css.map */
.services-container {
  width: 90%;
  margin: 70px auto 0px auto;
}

.section-title {
  text-align: left;
  font-size: 28px;
  font-weight: bold !important;
  margin-bottom: 20px;
}

.services-sections-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.services-sections-list .services-item {
  padding-top: 50px;
  width: 30%;
  min-width: 300px;
  max-width: 350px;
  border-radius: 15px;
  height: 420px;
  border: 1px solid rgb(209, 209, 209);
  margin-bottom: 20px;
}
.services-sections-list .service-image-shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
}
.services-sections-list .services-icons {
  height: 50px;
}
.services-sections-list .consultation-icon {
  padding-bottom: 20px;
  width: 80px;
}
.services-sections-list .service-description-text {
  padding: 15px 30px;
  font-size: 19px;
}

@media (max-width: 1024px) {
  .services-sections-list {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}/*# sourceMappingURL=Services.css.map */
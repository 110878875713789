.car-details-wrapper{
    min-height: calc(100vh - 200px);
    height: fit-content;
    padding-top: 80px;
    width: 90%;
    margin: auto;
    .car-detail-page-content{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .car-detail-page-divider{
        width: calc(50% - 20px);
        h3, h6{
            text-align: left;
            span{
                color: gray;
            }
        }
    }
    .car-details-information-box{
        margin-top: 50px;
        h5{
            text-align: left;
        }
    }
    .car-details-information-content{
        width: 100%;
        padding: 0;
        margin-top: 15px;
        li{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
        span{
            width: 50%;
            text-align: left;
        }
        .car-details-titles{
            color: gray;
        }
    }
}

.add-new-button{
    margin-right: 20px;
}

.description-box{
    margin-top: 50px;
    h4{
        text-align: left;
    }
    .car-description-text{
        font-size: 17px;
        color: rgb(57, 56, 56);
        padding: 15px;
        max-width: 750px;
        width: 100%;
        text-align: left;
    }
}

@media(max-width: 775px){
    .car-details-wrapper{
        .car-detail-page-content{
            flex-direction: column;
        }
        .car-detail-page-divider{
            width: 100%;
        }
        .car-details-header-content{
            margin-top: 40px;
        }
    }
}
.footer-container{
    background: rgb(0, 0, 27);
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-content{
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

.footer-logo{
    width: 170px;
}
.footer-navigation{
    text-align: left;
    li{
        color: white;
    } 
    a{
        color: white !important;
    }
}
.social-media-links{
    display: flex;
    align-items: center;
    li:hover{
        transform: scale(1.2);
        transition: 0.5s;
    }
    a{
        margin-left: 15px;
    }
}
.footer-button{
    background: rgb(0, 0, 66);
    border-radius: 15px;
    padding: 5px;
    height: fit-content;
    display: block;
}
.footer-contacts{
    text-align: left;
    color: white;
    a{
        color: white;
    }
}

@media (max-width: 970px){
    .footer-container{
        height: 240px;
    }
}

@media (max-width: 716px){
    .footer-container{
        height: 280px;
    }
}

@media (max-width: 599px){
    .footer-container{
        height: 320px;
    }
}

@media (max-width: 433px){
    .footer-container{
        height: 480px;
    }
    .footer-navigation{
        margin-top: 1rem;
    }
}
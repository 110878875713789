.register-as-dealer-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  z-index: -1;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.register-as-dealer-banner h2 {
  max-width: 450px;
}

.dealer-registration-form-container {
  width: 90%;
  max-width: 750px;
  padding: 25px;
  margin: -200px auto 0px auto;
  z-index: 11;
  background: white;
  position: relative;
  box-shadow: 0 0 25px 1px rgb(214, 214, 214);
  border-radius: 8px;
}
.dealer-registration-form-container .dealer-registration-form {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.dealer-registration-form-container .dealer-registration-form .form-group {
  width: 48%;
}

.dealer-services-container {
  width: 90%;
  margin: 130px auto 30px auto;
}
.dealer-services-container .extension-service-image {
  max-width: 650px;
  width: 40%;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.dealer-services-container .dashboard-service-image {
  max-width: 650px;
  width: 40%;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.dealer-services-container .dealers-service-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dealer-services-container .dealers-service-text {
  max-width: 450px;
  text-align: left;
  font-size: 21px;
  line-height: 34px;
  margin-bottom: 0px;
}

.consultation-container {
  margin: 80px 0;
}

.consultation-fields {
  width: calc(48% - 70px);
}

.consultation-form {
  max-width: 560px;
  margin: 30px auto auto auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 1025px) {
  .dealers-service-content {
    flex-direction: column;
  }
  .dealers-service-content img {
    width: 90% !important;
    max-width: 400px !important;
    margin-bottom: 20px !important;
  }
  .reverce {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 480px) {
  .dealer-registration-form {
    flex-direction: column;
  }
  .dealer-registration-form .form-group, .dealer-registration-form .MuiButtonBase-root {
    width: 100% !important;
  }
}/*# sourceMappingURL=RegisterAsDealer.css.map */